
.mail {
    color: #ff9f43 !important;

}

.sp {
    color: #ff9f43 !important;
}

.imglogo {
    width: 40%;
    margin: auto;
}

.back {
    background-color: #ff9f43 !important;
}

.error-span {
    font-size: smaller;
    color: #ea5455;
}

.nav.nav-tabs .nav-item .nav-link.active {
    position: relative;
    color: #ff9f43 !important;
    transition: all 0.2s ease;
}

.fotlink {
    padding: 5px;
    color: #ff9f43 !important;
}

.clearfix {
    font-size: 16px;
}

.nav.nav-tabs .nav-item .nav-link.active:after {
    left: 0;
    background: linear-gradient(30deg, #ff9f43, rgba(253, 165, 0, 0.651)) !important;
}

[dir] .form-control:focus {
    border: 1px solid #ff9f43 !important;
}

.dropdown .dropdown-menu .dropdown-item:hover {
    color: #fff !important;
    background-color: #ff9f43 !important;
}

[dir] .badge-primary {
    background-color: #ff9f43 !important;
}

.user-name {
    margin-top: 7px !important;
}

.spinner-border {
    width: 1rem !important;
    height: 1rem !important;
    display: block !important;
    margin: auto !important;
}

.rounded1 {
    border-radius: 50% !important;
}

.example {
    margin-bottom: 20px;
    margin-top: 20%;

    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    min-height: 270px;
}

.ant-spin-dot-item {
    background-color: #f2871e !important;
}

.iti__flag {
    background-image: url("images/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("images/flags@2x.png");
    }
}

.iti__country-list {
    direction: ltr !important;
}

.iti--allow-dropdown .iti__flag-container {
    direction: ltr !important;
}

.iti {
    width: 100% !important;
}

.hide {
    display: none;
}

.valid {
    font-size: smaller;
    margin-top: 0.25rem;
    color: rgba(29, 238, 40, 0.87);
    font-weight: 700;
}

.notvalid {
    font-size: smaller;
    margin-top: 0.25rem;
    color: #ea5455;
    font-weight: 700;
}

.imgstyle {
    min-height: 190px;
}

[dir=rtl] .iti--allow-dropdown input, [dir=rtl] .iti--allow-dropdown input[type=text], [dir=rtl] .iti--allow-dropdown input[type=tel], [dir=rtl] .iti--separate-dial-code input, [dir=rtl] .iti--separate-dial-code input[type=text], [dir=rtl] .iti--separate-dial-code input[type=tel] {
    padding-right: 90px !important;
}

.error-invalid {
    margin-top: 0.25rem;
    display: block;
    width: 100%;
    font-size: smaller;
    color: #ea5455;

}


.redeem {
    color: white;
    background: #009E15 0% 0% no-repeat padding-box !important;
    border: 1px solid #009E15 !important;
    border-radius: 0px 8px 8px 0px !important;
    opacity: 1 !important;
    padding: 6% 6% !important;
    cursor: pointer;
    margin-top: -3px;
    font-size: 14px;
    height: 37px !important;
    min-width: 97px;
}

.redeemVerifyOpactiy {
    background: #7cbd84 0% 0% no-repeat padding-box !important;
    border: 1px solid #7cbd84 !important;
    color: white;
    border-radius: 0px 8px 8px 0px !important;
    opacity: 1 !important;
    padding: 6% 6% !important;
    cursor: pointer;
    margin-top: -3px;
    font-size: 14px;
    height: 37px !important;
    min-width: 97px;
}

.headerVerify {
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.bodyVerify {
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.7;
}

.borderNone {
    border: none !important
}

.fontButton {
    font-size: 12.5px !important;
}


.css-ae0g2e {
    margin: auto;
}

body {
    --ReactInputVerificationCode-itemWidth: 4rem;
    --ReactInputVerificationCode-itemHeight: 3rem;
    --ReactInputVerificationCode-itemSpacing: 1.2rem;
}

@media (max-width: 500px) {
    body {
        --ReactInputVerificationCode-itemWidth: 2rem;
        --ReactInputVerificationCode-itemHeight: 2.5rem;
        --ReactInputVerificationCode-itemSpacing: 1.2rem;
    }
}

@media (max-width: 319px) {
    body {
        --ReactInputVerificationCode-itemWidth: 1.5rem;
        --ReactInputVerificationCode-itemHeight: 2rem;
        --ReactInputVerificationCode-itemSpacing: 1rem;
    }
}

.css-jxah1j {
    color: #F59E19;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    /*box-shadow: none!important;*/
}

.css-jxah1j.is-active {
    box-shadow: inset 0 0 0 1px #F59E19 !important;
}

.err {
    color: #ea5455 !important;
    font-size: 12px !important;
    text-align: right;
}

.css-1jkbsv7 {
    margin: auto !important;
}

.verifyButton {
    margin-top: 0.3% !important;
    margin-inline-start: -1%;

}

.verifyinput {
    flex: 1;
}

[dir=rtl] .iti--allow-dropdown input, [dir=rtl] .iti--allow-dropdown input[type=text], [dir=rtl] .iti--allow-dropdown input[type=tel], [dir=rtl] .iti--separate-dial-code input, [dir=rtl] .iti--separate-dial-code input[type=text], [dir=rtl] .iti--separate-dial-code input[type=tel] {
    padding-left: 2% !important;
}

[dir=rtl] .ReactInputVerificationCode__container {
    direction: ltr !important;
}

.notificationCard {
    min-height: 10rem;
    box-shadow: 0px 3px 15px #0000000D;
    opacity: 0.9;
    border-radius: 12px;
    background-color: rgba(98, 95, 95, 0.05);
}

.notificationImg {
    margin-top: -7%;
}

.notificationDate {
    font-size: 12px;

}

.SingleNotificationImg {
    max-height: 600px;
}

.SingleNotificationTitle {
    font-weight: bold;
    font-size: 23px;
    text-align: start !important;
}

.SingleNotificationTxt {
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 30px;
    text-align: start !important;
}

.articlesCard {;
    min-height: 17rem;
    border: 1px solid #4A4A4A;
    box-shadow: 0px 3px 15px #0000000D;
    opacity: 0.9;
    border-radius: 12px;
    background-color: rgba(98, 95, 95, 0.05);
}

.headWallet {
    font-size: 27px;
    text-transform: capitalize;
}

.InvestorButton {
    background-color: #28AFD9 !important;
    border-radius: 12px !important;
}

.walletNumber {
    color: #F5971C !important;
    text-transform: capitalize;
}

.moneyTxt {
    font-size: 16px;
}

.moneyNumBlue {
    font-size: 24px;
    color: #28AFD9 !important;
}

.dollarNumBlue {
    font-size: 16px;
    color: #28AFD9 !important;
    position: absolute;
    top: 45%;
    left: 20%;
}

.moneyNumOrange {
    font-size: 24px;
    color: #F5971C !important;
}

.dollarNumOrange {
    font-size: 16px;
    color: #F5971C !important;
    position: absolute;
    top: 45%;
    left: 20%;
}

.ul-navs {
    /*box-shadow: 0px 3px 6px #00000045 !important;*/
    border-radius: 9px !important;
    padding: 15px;
    border-radius: 12px;
}

.li-nav {
    font-size: 15px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    text-align: center;
}

.ui-tabs {
    border: solid 2px #ff9f43 !important;
    transform: translateY(-2px);
    border-radius: 12px !important;
    color: #000 !important;
}

.ui-tabs.active {
    background-color: #ff9f43 !important;
    border: solid 3px #ff9f43 !important;
    transform: translateY(-2px);
    border-radius: 12px !important;
    color: #fff !important;
}

.nav.nav-tabs .nav-item .nav-link.active {
    color: #fff !important;
}

body.dark-layout .nav .nav-item span .nav-link.active {
    color: #fff !important;
}

.nav.nav-tabs {
    box-shadow: none !important;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.nav.nav-tabs .nav-item .nav-link.active:after {
    display: none !important;
}

.Notify {
    width: 8px;
    height: 8px;
    background-color: #F59E19;
    border-radius: 50%;
    position: absolute;
    right: 15%;
}

.picnot {
    max-width: 100%;
    max-height: 400px;
}

.not_found {
    font-size: 2.525rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: -0.09px;
    text-align: center;
    color: #33333a;
}

.spanspin {
    color: #f2871e;
}

.backbutton {
    padding: 15px 50px !important;
    font-size: x-large !important;
    margin-top: 30px !important;
}

.explore_notFound {
    height: 2.625rem;

    font-size: 0.83rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #908e8e;
}

.btn_notFound {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.5 !important;
    letter-spacing: normal !important;
    text-align: center !important;
    color: #ffffff !important;
    padding: 0.813rem 1.938rem 0.75rem !important;
    border-radius: 12px !important;
    background-color: #f2871e !important;
    border-color: #f2871e !important;
    text-decoration: none !important;
}

#WalletTable {
    pointer-events: none;
}

.image_correct {
    padding: 20px;
    margin: 20px;
    background: #FF9A00;
    border-radius: 50%;
}

.error {
    color: #bb1414 !important;
    font-size: 12px !important;
    text-align: center;
}

@media print {
    .pdf-container {
        display: none;
    }
}

.pdf-container {
    display: flex;
    justify-content: center;
    user-select: none;
}

.controls {
    margin: 20px;
}

.alertEmpty {
    margin: auto;
    text-align: center;
    border-radius: 15px;
}

.TextEmpty {
    text-align: center;
    letter-spacing: 0px;
    opacity: 1;
    font-size: 20px;
}

.header-navbar .navbar-container {
    padding: 0px 3% !important;
}

.dark-layout .tabsTxt {
    color: #FFFFFF;
}

.nav.nav-tabs .nav-item .nav-link.active {
    color: #FFFFFF !important;
}

.dark-layout .nav-item .nav-link.active {
    color: #000000 !important;
}

.card-header:first-child {
    border-radius: unset !important;
}

.progressWrap {
    width: 100% !important;
    background-color: #FFFFFF !important;
}

.dark-layout .progressWrap {
    width: 100% !important;
    background-color: #2e2e2e !important;
}

.descriptionMeeting {
    font-weight: normal;
    font-size: 14px !important;
    line-height: normal;
}

.iconMeeting {
    max-width: 100%;
}

.shareHolders {
    color: #F59E19 !important;
    font-weight: bolder;
    margin-top: 15px;
    font-size: 18px;
}

.daysCounter {
    color: #57A9FF !important;
    font-weight: 600;
    font-size: 10px;
    margin-top: 10px;
}

.hoursCounter {
    color: #F59E19 !important;
    font-weight: 600;
    font-size: 10px;
    margin-top: 10px;
}

.minutesCounter {
    color: #61B500 !important;
    font-weight: 600;
    font-size: 10px;
    margin-top: 10px;
}

.secondsCounter {
    font-weight: 600;
    font-size: 10px;
    margin-top: 10px;
}

.daysCounterNumber {
    color: #57A9FF !important;
    font-weight: bolder;
    font-size: 35px;
}

.hoursCounterNumber {
    font-weight: bolder;
    color: #F59E19 !important;
    font-size: 35px;
}

.minutesCounterNumber {
    font-weight: bolder;
    color: #61B500 !important;
    font-size: 35px;
}

.secondsCounterNumber {
    font-weight: bolder;
    font-size: 35px;
}

.wrapper {
    position: relative;
}

.square {
    display: inline-block;
    text-align: center;
}

.strike {
    position: absolute;
    height: 2px;
    background: black;
    top: 9px;
    left: 0px;
    width: 100%;
}

.dark-layout .strike {
    position: absolute;
    height: 1px;
    background: #d9d9d9;
    top: 9px;
    left: 0px;
    width: 100%;
}

.dark-layout .react-pdf__Page span {
    color: unset !important;
}

.pdfIcon {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    border: none;
}

#mob {
    display: none !important;
}

#DateMob {
    display: none !important;
}

@media (max-width: 768px) {
    #DateMob {
        font-size: 11px;
        display: block !important;
    }

    #DateWeb {
        display: none !important;
    }

    #Web {
        display: none !important;
    }

    #mob {
        display: block !important;
    }

    .react-pdf__Page__canvas {
        max-width: 100% !important;
        height: auto !important;
    }

    .pdfIcon {
        width: 55px;
        height: 55px;
    }

    .controls {
        margin: 0px !important;
    }
}

.badge.badge-light-success {
    background: none !important;
}

.badge.badge-light-danger {
    background: none !important;
}

.logoutTXT {
    font-size: 15px !important;
    color: #F59E19 !important;
}
.ArticleTXT{
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    min-height: 40px;
}


body.dark-layout .sc-iJuUWI{
    color: #dad9d9;
    fill: #dad9d9 ;
}

body.dark-layout .sc-iJuUWI:disabled{
    color: rgba(175, 175, 175, 0.65);
    fill: rgba(175, 175, 175, 0.65);
}

.rdt_Pagination{
    align-content: center !important;
    justify-content: center !important;
}


.apexcharts-legend-text {
    margin: 0px 10px;
    font-size: 16px !important;
}

.apexcharts-tooltip {
    color: white !important;
}

.apexcharts-tooltip-text {
    margin: 0px 6px;
    font-size: 16px !important;
    color: white !important;
}

body.dark-layout .apexcharts-canvas .apexcharts-tooltip.light .apexcharts-tooltip-title, [dir] body.dark-layout .apexcharts-canvas .apexcharts-tooltip.light .apexcharts-tooltip-text, [dir] body.dark-layout .apexcharts-canvas .apexcharts-yaxistooltip .apexcharts-tooltip-title, [dir] body.dark-layout .apexcharts-canvas .apexcharts-yaxistooltip .apexcharts-tooltip-text, [dir] body.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-tooltip-title, [dir] body.dark-layout .apexcharts-canvas .apexcharts-xaxistooltip .apexcharts-tooltip-text, [dir] body.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-title, [dir] body.dark-layout .apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-text{
    background: none !important;
}
[dir] .apexcharts-title-text{
    transform:translateX(-40px) !important;
}
/*.apexcharts-text:first-child{*/
/*    transform:translateX(-25px) !important;*/
/*}*/

@media (max-width: 768px) {
    .apexcharts-legend{
        top: 100px !important;
    }
    [dir] .apexcharts-title-text{
        transform:translateX(0px) !important;
    }
    [dir] .apexcharts-datalabels:nth-child(3) {
        transform: translateX(-20px);
    }
    [dir] .apexcharts-datalabels:nth-child(4) {
        transform: translateX(20px);
    }

}
[dir] .apexcharts-datalabels:nth-child(3) {
    transform: translateX(-25px);
}
[dir] .apexcharts-datalabels:nth-child(4) {
    transform: translateX(25px);
}

#chartContainer .apexcharts-tooltip {
    color: red !important;
}

#chartContainer .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
    background: #ffffff !important;
}
.arrow_box{
    color: white !important;
    padding: 12px !important;
}
