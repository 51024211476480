.headerProfile {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 1;
}

.profileData {
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0px;
    text-transform: capitalize;
    opacity: 0.5;
}

.btnBuy {
    background: #007AA0 0% 0% no-repeat padding-box !important;
    border: 1px solid #007AA0 !important;
    border-radius: 8px !important;
    color: #FFFFFF !important;
    opacity: 1;
}

.edit-btnBuy {
    margin-left: 10px
}

.sc-kEjbxe {
    display: none
}

@media (max-width: 767px) {
    .edit-parent-button {
        display: flex;
        flex-direction: column;
    }

    .edit-btnBuy {
        margin-left: 0px !important;
    }
}

.percentage {
    font-size: 14px;
}

.percentage1 {
    font-size: 18px;
}
